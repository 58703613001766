import React from 'react';
import { Box } from '@mui/material';
import Hm2023 from "./hm2023.png";
import Hm2022 from "./hm2022.png";
import Hm2021 from "./hm2021.png";
import Hm2020 from "./hm2020.png";
import Hm2019 from "./hm2019.png";
import Hm2018 from "./hm2018.png";
import Hm2017 from "./hm2017.png";
import Hm2024 from "./hm2024.png";
const Awards = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: 2 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, maxWidth: 900 }}>
      <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2024} style={{ width: '100%', height: 'auto' }} alt="2024 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2023} style={{ width: '100%', height: 'auto' }} alt="2023 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2022} style={{ width: '100%', height: 'auto' }} alt="2022 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2021} style={{ width: '100%', height: 'auto' }} alt="2021 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2020} style={{ width: '100%', height: 'auto' }} alt="2020 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2019} style={{ width: '100%', height: 'auto' }} alt="2019 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2018} style={{ width: '100%', height: 'auto' }} alt="2018 Award" />
        </Box>
        <Box sx={{ width: { xs: '48%', sm: '22%', md: '12%' }, maxWidth: { xs: '80px', sm: '100px' }, textAlign: 'center' }}>
          <img src={Hm2017} style={{ width: '100%', height: 'auto' }} alt="2017 Award" />
        </Box>
      </Box>
    </Box>
  );
};

export default Awards;
