import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  CssBaseline,
  TextField,
  Divider,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the stylesheet
import axios from 'axios';
import BASE_URL from './ipconfig';
import NavbarV2 from './NavbarV2';
import Footerv2 from "./FooterV2"
export default function Bookus() {
  const [bookingDate, setBookingDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [picturesToUpload, setPicturesToUpload] = useState([]);
  const theme = useTheme();


  const handleFileChange = (event) => {
    setPicturesToUpload(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('dateOfBooking', bookingDate.toISOString());
    formData.append('name', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('description', description);
    for (let i = 0; i < picturesToUpload.length; i++) {
      formData.append('pictures', picturesToUpload[i]);
    }

    try {
      await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setFirstName('');
      setLastName('');
      setEmail('');
      setDescription('');
      setPicturesToUpload([]);
      alert('Booking confirmed! We will contact you soon.');
    } catch (error) {
      console.error('Error submitting the booking form', error);
      alert('There was an error submitting your booking. Please try again.');
    }
  };

  return (
<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
<CssBaseline />

<NavbarV2 />
<Box sx={{  backgroundColor: '#002855',color: '#fff', minHeight: '30vh', flexGrow: 3, display: 'flex', flexDirection: 'column', mt: theme.spacing(8) }}>

<Container sx={{ padding: '2rem', backgroundColor: '#002855', color: '#fff', minHeight: '100vh' }}>
      <Grid container spacing={4} alignItems="flex-start">
        {/* Left text content */}
        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Your Trusted Local Plumbing Experts
          </Typography>
          <Typography variant="h2" sx={{ color: '#FFCC00', fontWeight: 'bold', mb: 2 }}>
          Book Us Today for a Hassle-Free Fix!
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          By booking with us today, you're choosing a team that can handle any of your plumbing needs—whether residential or commercial. With years of expertise, top-notch service, and a commitment to quality, we ensure that every job is done right, on time, and with minimal disruption. Let us take care of your plumbing issues so you can focus on what matters most!
           
          </Typography>

          {/* Contact Information */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Contact Us
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> <a href="mailto:info@tornado-plumbing.com" style={{ color: '#FFCC00', textDecoration: 'none' }}>info@tornado-plumbing.com</a>
            </Typography>
            <Typography variant="body1">
              <strong>Phone:</strong> <a href="tel:6477848448" style={{ color: '#FFCC00', textDecoration: 'none' }}>(647) 784-8448</a>
            </Typography>
          </Box>
        </Grid>

        {/* Booking Form */}
        <Grid item xs={12} sm={6}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: '#fff',
              color: '#002855',
              p: 4,
              borderRadius: '8px',
              boxShadow: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center', color: '#002855' }}>
              Book Us Today
            </Typography>
            <Grid container spacing={2}>
              {/* Calendar at the top */}
              <Grid item xs={12}>
           
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Calendar
  onChange={setBookingDate}
  value={bookingDate}
  tileDisabled={({ date, view }) =>
    view === 'month' && date.getDay() === 0 // Disable Sundays
  }
  sx={{
    width: '100%',
    maxWidth: '400px', // Set a maximum width for the calendar
    margin: '0 auto',  // Center the calendar
  }}
/>

</Box>
              </Grid>

              {/* First Name and Last Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>

              {/* File Upload */}
              <Grid item xs={12} sm={7} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <label htmlFor="upload-pictures">
    <Button variant="contained" component="span">
      Upload Pictures
    </Button>
  </label>
  <input
    id="upload-pictures"
    type="file"
    multiple
    hidden
    onChange={handleFileChange}
  />
  {picturesToUpload.length > 0 && (
    <Typography variant="body2" sx={{ mt: 1 }}>
      {picturesToUpload.length} file(s) selected
    </Typography>
  )}
</Grid>

              {/* Submit and Upload buttons aligned at the bottom */}
              <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
  <Button variant="contained" color="primary" type="submit">
    Confirm Booking
  </Button>
</Grid>

            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>

</Box>
<Footerv2/>
    
</Box>




    
  );
}
