import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import axios from 'axios'; // Import axios for making HTTP requests
import background from './cat.webp'; // Import the background image
import BASE_URL from './ipconfig'; // Import the BASE_URL
const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({ username: '', password: '' });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send login data to the backend using axios
      const response = await axios.post(`${BASE_URL}/api/accounts/login`, loginData);

      if (response.data.status) {
        localStorage.setItem('user_type', response.data.user.role); // Store user type in localStorage
        navigate('/'); // Redirect to home page after successful login
      } else {
        alert(response.data.msg); // Show error message if login fails
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred during login. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h1">UNICLUB</Typography>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                id="username"
                fullWidth
                name="username"
                label="Username"
                placeholder="Username"
                type="text"
                onChange={handleChange}
                value={loginData.username}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                placeholder="Password"
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleChange}
                value={loginData.password}
              />
              <Button
                className="login-btn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
              >
                Log In
              </Button>
              <Divider sx={{ mt: 2, mb: 2 }}>
                <Typography>OR</Typography>
              </Divider>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => navigate('/')}
              >
                GO BACK
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
