import * as React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { green, orange } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import swimLogo from './logoupdatedfordrain.png';
import BASE_URL from './ipconfig'; // Import the BASE_URL
import Signup from './Signup';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ThinNavbar from './Thinnavbar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

const drawerWidth = 240;
const navItems = ['HOME', 'Our Services'];

function NavbarV2(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isExtraSmallScreen = useMediaQuery('(max-width:400px)');
  const handleClick = () => {
    setOpen(!open);
  };
  
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const [open, setOpen] = React.useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("admin_logged_in");
    localStorage.removeItem("user_type");
    navigate("/Login");
  };

  const handleDarkModeToggle = (event) => {
    setDarkMode(event.target.checked);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem style={{ textDecoration: 'none', color: '#2196f3', display: 'flex' }} key={index} disablePadding>
            <ListItemButton component={Link} to={`/${item.replace(/\s+/g, '-').toLowerCase()}`} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItemButton>
          </List>
        </Collapse>
        <Switch
          defaultChecked
          color="warning"
          checked={darkMode}
          onChange={handleDarkModeToggle}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ ml: 2 }}
        />
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const userType = localStorage.getItem("user_type");
  const isLoggedIn = !!userType;
  const avatarColor = userType === "admin" ? orange[500] : green[500];
  const userInitial = isLoggedIn ? (userType === "admin" ? "A" : "U") : null;

  const logoAlignment = isSmallScreen ? { justifyContent: 'center', width: '100%' } : { justifyContent: 'flex-start' };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="static" sx={{ height: 'auto', backgroundColor: '#2196f3', padding: '4px 0' }}>
        <Toolbar sx={{
          minHeight: '40px !important',
          justifyContent: 'space-between',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          gap: 1,
          paddingX: 2
        }}>
          {/* Left Section */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: isSmallScreen ? 'center' : 'flex-start',
            width: isSmallScreen ? '100%' : 'auto',
          }}>
            <AccessTimeIcon fontSize="small" />
            <Typography variant="body2" component="div" sx={{ whiteSpace: 'nowrap', fontSize: isExtraSmallScreen ? '12px' : 'inherit' }}>
              Open: 9am - 5pm (Mon - Sat)
            </Typography>
            <LocationOnIcon fontSize="small" />
            <Typography variant="body2" component="div" sx={{ whiteSpace: 'nowrap', fontSize: isExtraSmallScreen ? '12px' : 'inherit' }}>
              45 Chauncey Ave, Etobicoke
            </Typography>
          </Box>

          {/* Right Section */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: isSmallScreen ? 'center' : 'flex-end',
            width: isSmallScreen ? '100%' : 'auto',
          }}>
            <IconButton href="https://www.facebook.com/TornadoPlumbingCom/" target="_blank" color="inherit">
              <FacebookIcon />
            </IconButton>
            <IconButton href="https://www.youtube.com/watch?v=ZuIIYSUPvvQ" target="_blank" color="inherit">
              <YouTubeIcon />
            </IconButton>
            <IconButton href="https://www.instagram.com/tornado_plumbing/" target="_blank" color="inherit">
              <InstagramIcon />
            </IconButton>
            <IconButton href="https://www.pinterest.ca/mkashul/_created/" target="_blank" color="inherit">
              <PinterestIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavbarV2;
