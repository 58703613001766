import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import BASE_URL from './ipconfig'; // Ensure this path is correct

export default function ServiceWork() {
  const theme = useTheme();
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/dialogues`);
      const formattedServices = response.data.map((dialogue) => ({
        id: dialogue.id,
        title: dialogue.title,
        image: `${BASE_URL}/${dialogue.picture}`,
        description: dialogue.text,
      }));
      setServices(formattedServices);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  return (

      <Container
        sx={{
          maxWidth: '1350px', // Set maximum width for the content
          margin: '0 auto', // Center the container
          padding: theme.spacing(0, 2), // Horizontal padding
        }}
      >
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} key={service.id}>
              {/* Center the card within the grid item */}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      md: index % 2 === 0 ? 'row' : 'row-reverse',
                    },
                    alignItems: 'stretch', // Ensure image and content have the same height
                    boxShadow: 3,
                    borderRadius: 2,
                    overflow: 'hidden',
                    maxWidth: {
                      xs: '100%',
                      md: 800, // Adjust as needed (image width + content width)
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: 416, // Fixed width to make the image square
                      height: 416, // Fixed height to make the image square
                      objectFit: 'cover',
                    }}
                    image={service.image}
                    alt={service.title}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      padding: theme.spacing(2),
                      width: '100%',
                    }}
                  >
                    <CardContent
                      sx={{
                        flex: '1 0 auto',
                      }}
                    >
                      <Typography component="div" variant="h5" gutterBottom>
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
 
  );
}
