import * as React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { green, orange } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import swimLogo from './tornadologo.webp';
import BASE_URL from './ipconfig'; // Import the BASE_URL

const drawerWidth = 240;
const navItems = ['Services', 'Our Work', 'Booking'];

function NavbarV2(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(() => {
    // Get initial mode from localStorage or default to false
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  useEffect(() => {
    // Save dark mode state to localStorage whenever it changes
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('admin_logged_in');
    localStorage.removeItem('user_type');
    navigate('/Login');
  };

  const handleDarkModeToggle = (event) => {
    setDarkMode(event.target.checked);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '64px', // Adjust padding to ensure the first item isn't covered
        overflowY: 'auto',  // Make sure the drawer content scrolls if necessary
      }}
    >
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={`/${item.replace(/\s+/g, '-').toLowerCase()}`}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const userType = localStorage.getItem('user_type');
  const isLoggedIn = !!userType;
  const avatarColor = userType === 'admin' ? orange[500] : green[500];
  const userInitial = isLoggedIn ? (userType === 'admin' ? 'A' : 'U') : null;

  const logoAlignment = isSmallScreen
    ? { justifyContent: 'center', width: '100%' }
    : { justifyContent: 'flex-start' };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure AppBar is on top
          backgroundColor: darkMode ? '#212121' : '#c8e4fb',
          color: darkMode ? '#bdbdbd' : '#2196f3',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', ...logoAlignment }}>
            <Link
              to="/Home"
              style={{
                textDecoration: 'none',
                color: darkMode ? '#bdbdbd' : '#212121',
              }}
            >
              <img
                src={swimLogo}
                alt="Swim Logo"
                style={{ margin: '0 auto', height: '40px', width: 'auto' }}
              />
            </Link>
          </Box>
          {!isSmallScreen && (
            <Link
              to="/Home"
              style={{
                textDecoration: 'none',
                color: darkMode ? '#bdbdbd' : '#212121',
                display: 'flex',
              }}
            >
              
            </Link>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item, index) => (
              <Button
                key={index}
                component={Link}
                to={`/${item.replace(/\s+/g, '-').toLowerCase()}`}
                sx={{ color: darkMode ? '#bdbdbd' : '#212121' }}
              >
                {item}
              </Button>
            ))}
          </Box>
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
            {isLoggedIn ? (
              <div>
                <Avatar
                  sx={{ bgcolor: avatarColor, cursor: 'pointer' }}
                  onClick={handleAvatarClick}
                >
                  {userInitial}
                </Avatar>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  {userType === 'admin' && (
                    <MenuItem
                      sx={{ color: darkMode ? '#212121' : '#212121' }}
                      component={Link}
                      to="/HomeAdmin"
                    >
                      Admin Dashboard
                    </MenuItem>
                  )}
                  <MenuItem
                    sx={{ color: darkMode ? '#212121' : '#212121' }}
                    onClick={handleLogout}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <Button
                
            
              >
           
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: darkMode ? '#212121' : '#FFFFFF',
            color: darkMode ? '#bdbdbd' : '#2196f3',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default NavbarV2;
