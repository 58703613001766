import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import groupic from './groupic.png'; // Ensure the image is in your project directory
import axios from 'axios';
import BASE_URL from './ipconfig';

export default function Hiring() {
  const [openBookDialog, setOpenBookDialog] = useState(false);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [picturesToUpload, setPicturesToUpload] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleBookClickOpen = () => {
    setOpenBookDialog(true);
  };

  const handleBookClose = () => {
    setOpenBookDialog(false);
  };

  const handleFileChange = (event) => {
    setPicturesToUpload(event.target.files);
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('dateOfBooking', bookingDate.toISOString());
    formData.append('name', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('description', description);
    for (let i = 0; i < picturesToUpload.length; i++) {
      formData.append('pictures', picturesToUpload[i]);
    }

    try {
      await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleBookClose();
    } catch (error) {
      console.error('Error submitting the booking form', error);
    }
  };

  const fetchVideos = async () => {
    const response = await axios.get(`${BASE_URL}/videos`);
    setVideos(response.data);
    if (response.data.length > 0) {
      setSelectedVideo(response.data[0]);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#002855', color: '#fff', width: '100%' }}>
      <Container sx={{ padding: '2rem' }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left text content */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              YOUR LOCAL PLUMBING COMPANY
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: '#FFCC00', fontWeight: 'bold', mb: 2 }}
            >
              Let Us Fix Your Plumbing Problems
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              “Tornado Plumbing and Drains did an excellent job replacing my old
              drains and correcting some very old plumbing in my old house in
              only 2 days.”
              <br />
              <strong>– Lorraine Wood (Home Owner)</strong>
            </Typography>
          </Grid>

          {/* Right image or video content */}
          <Grid item xs={12} sm={6}>
            {selectedVideo ? (
              <Box sx={{ textAlign: 'center', my: 2 }}>
                <Box
                  sx={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    height: 0,
                    overflow: 'hidden',
                    maxWidth: '100%',
                    backgroundColor: '#000',
                  }}
                >
                  <iframe
                    src={`https://www.youtube.com/embed/${new URL(
                      selectedVideo.link
                    ).searchParams.get('v')}`}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video player"
                  ></iframe>
                </Box>
              </Box>
            ) : (
              <Box
                component="img"
                src={groupic}
                alt="Plumbing team"
                sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
            )}
          </Grid>

          {/* Bottom button */}
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
            <Box
              sx={{
                backgroundColor: '#FFCC00',
                p: 3,
                borderRadius: '8px',
                display: 'inline-block',
                boxShadow: 3,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', color: '#002855' }}
              >
                Industry-Leading Plumbers since 2014
              </Typography>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  backgroundColor: '#002855',
                  color: '#fff',
                  paddingX: 3,
                  paddingY: 1.5,
                  fontSize: '16px',
                }}
                onClick={handleBookClickOpen}
              >
                BOOK US TODAY
              </Button>

              <Divider
                sx={{
                  my: 2,
                  borderColor: '#002855',
                  width: '80%',
                  margin: '16px auto',
                }}
              >
                {' '}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#002855' }}
                >
                  OR
                </Typography>{' '}
              </Divider>

              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: '#002855',
                  color: '#fff',
                  paddingX: 3,
                  paddingY: 1.5,
                  fontSize: '16px',
                }}
                href="tel:647-784-8448"
              >
                CALL US: 647-784-8448
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Booking dialog */}
      <Dialog
        open={openBookDialog}
        onClose={handleBookClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          BOOK US TODAY
          <IconButton
            aria-label="close"
            onClick={handleBookClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ width: '100%', mt: 2 }}
            >
              <Typography variant="body2" color="textSecondary">
                Select a date for booking:
              </Typography>
              <Calendar
  onChange={setBookingDate}
  value={bookingDate}
  tileDisabled={({ date, view }) =>
    view === 'month' && date.getDay() === 0 // Disable Sundays
  }
  sx={{
    width: '100%',
    maxWidth: '400px', // Set a maximum width for the calendar
    margin: '0 auto',  // Center the calendar
  }}
/>
            </Box>
            <TextField
              required
              fullWidth
              label="First Name"
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Last Name"
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Confirm Booking
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
