import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ServiceWork from './servicework';
import News from "./News";

const renderStars = (rating) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {Array.from({ length: 5 }).map((_, index) =>
        index < rating ? (
          <StarIcon key={index} sx={{ color: '#fb8e28' }} />
        ) : (
          <StarBorderIcon key={index} sx={{ color: '#fb8e28' }} />
        )
      )}
    </Box>
  );
};

export default function Servicesprovided() {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', width: '100%' }}>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            mt: theme.spacing(8),
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" color="text.primary">
            OUR SERVICES
          </Typography>
          <Typography variant="h6" color="text.secondary">
            For all your needs, we provide plumbing services across GTA area
          </Typography>
          <Typography variant="h6" color="text.secondary">
            We provide residential & commercial work
          </Typography>
        </Box>
        <Grid item xs={12}>
          <News />
        </Grid>
      </Container>
    </Box>
  );
}
