import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NavbarV2 from './NavbarV2';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import PhoneIcon from '@mui/icons-material/Phone';
import TextField from '@mui/material/TextField';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import image1 from './301149057_453900453420728_8473904004180223664_n.jpg';
import { Card } from '@mui/material';
import image2 from  './395487763_730700209074083_757152542692046785_n.jpg';
import image3 from  './301149057_453900453420728_8473904004180223664_n.jpg';
import image4 from './301149057_453900453420728_8473904004180223664_n.jpg';
import Appbartest from "./Appbartest"
import Testimonials from './Testimonials';
import LocationHours from './Locationhours';
import Servicesprovided from './Servicesprovided';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the stylesheet
import BASE_URL from './ipconfig'; // Import the BASE_URL
import StarScore from './Starscore';
import Awards from './Awards';
import ThinNavbar from './Thinnavbar';
import Hiring from './Hiring';
import Hiringv2 from './Hiringv2';
import Footerv2 from "./FooterV2"
import Guarantee from './Guarantee';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const HeroBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  transition: 'background-image 1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: theme.spacing(1),
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const urlify = (text) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return text.split(urlPattern).map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <Link href={part} target="_blank" rel="noopener noreferrer" key={index}>
          {part}
        </Link>
      );
    }
    return part;
  });
};

export default function Home() {
  const [announcements, setAnnouncements] = useState([]);
  const [events, setEvents] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [dialogues, setDialogues] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [expanded, setExpanded] = useState({});
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
  const [imageDimensions, setImageDimensions] = useState({ width: '90vw', height: '90vh' });
  const [openBookDialog, setOpenBookDialog] = useState(false);

  const [bookingDate, setBookingDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [picturesToUpload, setPicturesToUpload] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const pictureReel = [image1, image2, image3, image4];

  useEffect(() => {
    fetchAnnouncements();
    fetchEvents();
    fetchPictures();
    fetchVideos();
    fetchDialogues();

    const pictureInterval = setInterval(() => {
      setCurrentPictureIndex((prevIndex) => (prevIndex + 1) % pictureReel.length);
    }, 10000);

    return () => clearInterval(pictureInterval);
  }, []);

  const fetchAnnouncements = async () => {
    const response = await axios.get(`${BASE_URL}/announcements`);
    setAnnouncements(response.data);
  };

  const fetchEvents = async () => {
    const response = await axios.get(`${BASE_URL}/events`);
    setEvents(response.data);
  };

  const fetchPictures = async () => {
    const response = await axios.get(`${BASE_URL}/pictures`);
    setPictures(response.data);
  };

  const fetchVideos = async () => {
    const response = await axios.get(`${BASE_URL}/videos`);
    setVideos(response.data);
    if (response.data.length > 0) {
      setSelectedVideo(response.data[0]);
    }
  };

  const fetchDialogues = async () => {
    const response = await axios.get(`${BASE_URL}/dialogues`);
    setDialogues(response.data);
  };

  const handleClickOpen = (picture) => {
    const img = new Image();
    img.src = `${BASE_URL}/${picture.path}`;
    img.onload = () => {
      setCurrentImage(picture);
      setOpen(true);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const handleBookClickOpen = () => {
    setOpenBookDialog(true);
  };

  const handleBookClose = () => {
    setOpenBookDialog(false);
  };

  const handleFileChange = (event) => {
    setPicturesToUpload(event.target.files);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('dateOfBooking', bookingDate.toISOString());
    formData.append('name', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('description', description);
    for (let i = 0; i < picturesToUpload.length; i++) {
      formData.append('pictures', picturesToUpload[i]);
    }
  
    try {
      await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleBookClose();
    } catch (error) {
      console.error('Error submitting the booking form', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
    <CssBaseline />

    <NavbarV2 />
    
    <Box sx={{ flexGrow: 3, display: 'flex', flexDirection: 'column', mt: theme.spacing(8) }}>
      
    <Grid container >
    <Grid item xs={12} >
    
<Hiringv2/>



    </Grid>
    <Grid item xs={12}>

    <Guarantee/>

      
</Grid>
<Grid item xs={12}>

<Testimonials />

      
</Grid>
<Grid item xs={12}>


<Servicesprovided />


      
</Grid>
<Grid item xs={12}>

<Footerv2/>
      
</Grid>
    </Grid>
        

     
   
        
      
           
              


    

         
       
        

       
      </Box>


      
    </Box>
  );
}
