import React from 'react';
import { Box, Typography, Link, SvgIcon, LinearProgress, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import homeStarImage from "./icon-verified-blue-d72416122c343854acf3e0457601158b85f02d1384b5a18d7c2010410fe4616e.png";

const StarScore = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2, width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5">
              <Link href="https://homestars.com/companies/2879877-tornado-plumbing#review_filters" color="inherit">
                <strong>Star Score</strong>
              </Link>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
              <SvgIcon component={StarIcon} sx={{ color: '#FFC107', fontSize: 60 }} />
              <Typography variant="h4" sx={{ ml: 1 }}>
                <strong>98%</strong>
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body1">Average Rating</Typography>
                <LinearProgress variant="determinate" value={99} sx={{ height: 10, borderRadius: 5 }} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Recency</Typography>
                <LinearProgress variant="determinate" value={99} sx={{ height: 10, borderRadius: 5 }} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Reputation</Typography>
                <LinearProgress variant="determinate" value={99} sx={{ height: 10, borderRadius: 5 }} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Responsiveness</Typography>
                <LinearProgress variant="determinate" value={99} sx={{ height: 10, borderRadius: 5 }} />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <Typography variant="h6">Reviews by rating </Typography>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">Great</Typography>
              <LinearProgress variant="determinate" value={99} sx={{ height: 10, borderRadius: 5 }} />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">Average</Typography>
              <LinearProgress variant="determinate" value={3} sx={{ height: 10, borderRadius: 5, backgroundColor: '#eee' }} />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">Poor</Typography>
              <LinearProgress variant="determinate" value={3} sx={{ height: 10, borderRadius: 5, backgroundColor: '#eee' }} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: { xs: 2, md: 0 } }}>
            <img src={homeStarImage} style={{ width: '80px', height: 'auto' }} alt="Verified" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StarScore;
