import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Grid,
  CssBaseline,
  Container,
  Typography,
} from '@mui/material';
import NavbarV2 from './NavbarV2';
import ServiceWork from './servicework';
import Serviceworkv2 from './Serviceworkv2';
import FooterV2 from './FooterV2';
export default function Services() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        overflow: 'hidden',
        width: '100%', // Ensure full width
      }}
    >
      <CssBaseline />

      <NavbarV2 />

      {/* Background Box */}
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#f5f5f5', // Apply background color here
          padding: theme.spacing(4, 0), // Vertical padding
        }}
      >
        {/* Use a Container to center content and control width */}
        <Container
          maxWidth="md" // Set the maximum width to 'md' breakpoint (approximately 960px)
          sx={{
            padding: '2rem',
            mt: theme.spacing(8),
          }}
        >
          <Grid container spacing={2} alignItems="center">
            {/* Content */}
            <Grid item xs={12}>
              <Serviceworkv2 />
            </Grid>
        
          </Grid>
          
        </Container>

      </Box>
      <FooterV2/>
    </Box>
  );
}
