import React from "react";
import {
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
  Divider,
  Container,
  Button 
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import tornadoLogo from "./tornadologo.webp"; // Import the tornado logo
import PinterestIcon from '@mui/icons-material/Pinterest';
const Footer = () => {
  return (
    <Box
      sx={{ backgroundColor: "#00274C", color: "#FFFFFF", padding: "2rem 0" }}
    >
      <Container maxWidth="lg">
        <Grid container justifyContent="center" spacing={4}>
          {/* Top Section with Contact Info */}
          <Grid container item xs={12} spacing={4} justifyContent="center">
            {/* Address */}
            <Grid item xs={12} sm={4} textAlign="center">
              <Link
                href="https://www.google.com/maps/place/45+Chauncey+Ave,+Etobicoke,+ON+M8Z+2Z2"
                color="inherit"
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocationOnIcon
                    sx={{ fontSize: 40, color: "#FFD700", mr: 2 }}
                  />
                  <Typography variant="h6">
                    45 Chauncey Ave, Etobicoke, ON M8Z 2Z2
                  </Typography>
                </Box>
              </Link>
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={4} textAlign="center">
              <Link
                href="mailto:info@tornado-plumbing.com"
                color="inherit"
                underline="none"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EmailIcon sx={{ fontSize: 40, color: "#FFD700", mr: 2 }} />
                  <Typography variant="h6">info@tornado-plumbing.com</Typography>
                </Box>
              </Link>
            </Grid>

            {/* Phone */}
            <Grid item xs={12} sm={4} textAlign="center">
              <Link href="tel:6477848448" color="inherit" underline="none">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PhoneIcon sx={{ fontSize: 40, color: "#FFD700", mr: 2 }} />
                  <Typography variant="h6">(647) 784-8448</Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider sx={{ borderColor: "#FFD700", marginY: "1rem" }} />
          </Grid>

          {/* Middle Section with Logo, Text, and Social Media Icons */}
          <Grid container item xs={12} spacing={4} justifyContent="center">
            {/* Logo and Company Info */}
            <Grid item xs={12} sm={4} textAlign="center">
              <img
                src={tornadoLogo}
                alt="Tornado Plumbing & Drains"
                style={{ height: "80px", marginBottom: "1rem" }}
              />
              <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                With over 9 years of experience in plumbing and drain services,
                we have proven ourselves as one of the leaders in the plumbing
                industry across Greater Toronto Area.
              </Typography>
              {/* Social Media Icons */}
              <Box sx={{ marginTop: "1rem" }}>
              <IconButton href="https://www.facebook.com/TornadoPlumbingCom/" target="_blank" color="inherit">
                        <FacebookIcon />
                      </IconButton>
                      <IconButton href="https://www.youtube.com/watch?v=ZuIIYSUPvvQ" target="_blank" color="inherit">
                        <YouTubeIcon />
                      </IconButton>
                      <IconButton href="https://www.instagram.com/tornado_plumbing/" target="_blank" color="inherit">
                        <InstagramIcon />
                      </IconButton>
                      <IconButton href="https://www.pinterest.ca/mkashul/_created/" target="_blank" color="inherit">
                        <PinterestIcon />
                      </IconButton>
              </Box>
            </Grid>

            {/* Useful Links */}
            <Grid item xs={12} sm={4} textAlign="center">
              <Typography variant="h6">Useful Links</Typography>
              <Link href="#" color="inherit" underline="none">
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  About Us
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none">
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  Careers
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none">
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  News & Articles
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none">
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  Legal Notice
                </Typography>
              </Link>
              <Link href="#" color="inherit" underline="none">
                <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                  Contact Us
                </Typography>
              </Link>
            </Grid>

            {/* License Info */}
            <Grid item xs={12} sm={4} textAlign="center">
              <Typography variant="h6">Licenses</Typography>
              <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                Building renovator: T85-4728632
              </Typography>
              <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                Plumbing contractor: T94-4992639
              </Typography>
              <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                Plumbing license: FI6216638
              </Typography>
              <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                Drain contractor: T87-4722944
              </Typography>
              <Typography variant="body1" sx={{ marginTop: "1rem" }}>
                Master plumber: T95-4969603
              </Typography>
            </Grid>
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider sx={{ borderColor: "#FFD700", marginY: "1rem" }} />
          </Grid>

          {/* Bottom Section with Copyright Info */}
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body2"
              color="inherit"
              sx={{ marginTop: "2rem" }}
            >
              Copyright © 2024 Tornado Plumbing & Drains. All rights
              reserved.
            </Typography>
            <Typography
              variant="body2"
              color="inherit"
              sx={{ marginTop: "1rem" }}
            >
             Built by
            </Typography>
            
            <Button
  href="https://www.illumawebsolutions.com"
  target="_blank"
  rel="noopener"
  variant="text"
  color="inherit"
  sx={{ textTransform: 'none', fontSize: '0.875rem' }}
>
  Illuma Web Solutions
</Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
