import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import plumbing1 from './plumbing1.png';
import plumbing2 from './plumbing2.png';
import plumbing3 from './unnamed.jpg';
import plumbing4 from './plumbing4.png';
import signature from './signature.png'; // Importing the signature image

const Guarantee = () => {
  return (
    <Box sx={{ backgroundColor: '#f5f5f5', width: '100%' }}>
      <Container sx={{ padding: '2rem' }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left side images */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing1}
                  alt="Plumbing 1"
                  sx={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing2}
                  alt="Plumbing 2"
                  sx={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing3}
                  alt="Plumbing 3"
                  sx={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={plumbing4}
                  alt="Plumbing 4"
                  sx={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Right side text content */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: '#555', mb: 1 }}>
              OUR GUARANTEE
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
              Reputable and Licensed Team for Your Next Project!
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#FFCC00',
                    mr: 1,
                  }}
                >
                  $
                </Box>
                Guaranteed best price!
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#FFCC00',
                    mr: 1,
                  }}
                >
                  ⏰
                </Box>
                Job completed on time!
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#FFCC00',
                    mr: 1,
                  }}
                >
                  🏗️
                </Box>
                Licensed and insured company!
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{ fontStyle: 'italic', fontWeight: 'bold', mt: 2 }}
            >
              Serhiy Marunchuk
            </Typography>
            <Typography variant="body2">Company Owner</Typography>

            {/* Signature Image */}
            <Box sx={{ mt: 2 }}>
              <Box
                component="img"
                src={signature}
                alt="Signature"
                sx={{ width: '150px' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Guarantee;
