import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Awards from './Awards';
import StarScore from './Starscore';

// Import local avatar images
import avatar1 from './avatar1.jpg';
import avatar2 from './avatar2.jpg';
import avatar3 from './avatar3.jpg';
import avatar4 from './avatar4.jpg';
import avatar5 from './avatar5.jpg';
import avatar6 from './avatar6.jpg';
import avatar7 from './avatar7.jpg';

const googleReviews = [
  {
    avatar: <Avatar alt="D K (Three)" src={avatar1} />,
    name: 'D K (Three)',
    occupation: '7 months ago',
    testimonial: "Don't bother calling other companies. Called in few for estimates before going with Tornado given positive reviews and fair pricing. Fast, clean, polite service. Serhiy was very professional, explained everything including what to look out for after. This is not a small job, replacing sewer pipe (roots issue). They checked water and gas pipe to ensure safety. Everything done within one day. 10 out of 10.",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Christine Waselynchuk" src={avatar2} />,
    name: 'Christine Waselynchuk',
    occupation: '8 months ago',
    testimonial: "Absolutely fantastic..would give more than 5/5 if I could. Prompt friendly great service reasonable and Stress Relieved my situation. Thanks so much will definitely call again if needed and recommend highly.!!!",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Sergey Haletski" src={avatar3} />,
    name: 'Sergey Haletski',
    occupation: '10 months ago',
    testimonial: 'Had a drain clog repaired. Made an appointment quickly, came on schedule, professional and efficient. Highly recommend them. Thank you!',
    rating: 5,
  },
  {
    avatar: <Avatar alt="Andrea Kelly" src={avatar4} />,
    name: 'Andrea Kelly',
    occupation: '11 months ago',
    testimonial: "Drain Fix and Clean Out Installation Serghiy is absolutely amazing! When I had a drain backup, he promptly arrived and efficiently fixed the issue with precision and care. His professionalism, expertise, and friendly demeanour were evident throughout the process. Serghiy not only resolved the immediate problem but also provided helpful maintenance tips and installed a clean out to make it easier to access in the future. I highly recommend him for any plumbing needs his top-notch service and commitment to customer satisfaction make him the go-to plumber. Thanks, Serghiy!",
    rating: 5,
  },
  {
    avatar: <Avatar alt="lorraine wood" src={avatar5} />,
    name: 'lorraine wood',
    occupation: 'a year ago',
    testimonial: "Tornado Plumbing and Drains did an excellent job replacing my old drains and correcting some very old plumbing in my old house in only 2 days. Peter was very thorough in evaluating and explaining in detail the problems with the drains and how they would be repaired. He was extremely patient in answering all of my concerns. The team showed up on time, protected the work areas and did an excellent job with the final clean-up. They are assisting me with the rebate for the valve also. The cost for the work was very reasonable and several thousand dollars less than two other companies. I have already recommended Tornado to several friends and recommend them without reservation.",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Oleg Belenki" src={avatar6} />,
    name: 'Oleg Belenki',
    occupation: '5 years ago',
    testimonial: "Last week Sergey and his team from Tornado Plumbing had replaced the sewer drain in our house and installed a new back water valve. We had a few plumbing projects completed by them in a past and were very satisfied with their competitive price and a highest level of services provided. We were glad we contacted Tornado this time again. Everything was completed on time and both our front yard and a basement were left very clean. Upon completion of the job Sergey came back and explained the service details for a new valve and also helped us to fill the paperwork for the city rebate. All in all the Tornado service was five stars out of five, we were very satisfied and would not hesitate to hire Sergey and his team again.",
    rating: 5,
  },
  {
    avatar: <Avatar alt="Claudia Taylor" src={avatar7} />,
    name: 'Claudia Taylor',
    occupation: '5 years ago',
    testimonial: "Excellent work and great attention to detail. Very pleased with Serhiy and his team. Highly recommend their services. We had a 2-piece basement washroom that we wanted roughed-in to a 3-piece, plus some additional work in the laundry room. We are very pleased with the results and at the competitive price.",
    rating: 5,
  },
];

const renderStars = (rating) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {Array.from({ length: 5 }).map((_, index) =>
        index < rating ? (
          <StarIcon key={index} sx={{ color: '#fb8e28' }} />
        ) : (
          <StarBorderIcon key={index} sx={{ color: '#fb8e28' }} />
        )
      )}
    </Box>
  );
};

export default function ProgressMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md')); // Checks if the screen is larger than the 'md' breakpoint
  const reviewsPerPage = isLargeScreen ? 3 : 1; // Show 3 reviews per page on large screens, 1 review per page on small screens
  const maxSteps = Math.ceil(googleReviews.length / reviewsPerPage);

  // Adjust the activeStep when the screen size changes
  React.useEffect(() => {
    if (activeStep >= maxSteps) {
      setActiveStep(maxSteps - 1);
    }
  }, [reviewsPerPage, maxSteps, activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ backgroundColor: '#c2eafc', width: '100%' }}>
      <Container
        id="testimonials"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" color="text.primary">
            Awards & Testimonials
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Discover why our customers love our services. Experience our unmatched efficiency,
            quality, and customer satisfaction. Book us today to meet all your plumbing and
            drainage needs.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: 2,
                p: 2,
              }}
            >
              {googleReviews
                .slice(activeStep * reviewsPerPage, (activeStep + 1) * reviewsPerPage)
                .map((review, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: '100%',
                      maxWidth: '32%',
                      minWidth: isLargeScreen ? '30%' : '100%',
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {review.avatar}
                        <Box sx={{ ml: 2, flexGrow: 1 }}>
                          <Typography variant="subtitle1">{review.name}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {review.occupation}
                          </Typography>
                          {renderStars(review.rating)}
                        </Box>
                      </Box>
                      <Box sx={{ maxHeight: 100, overflowY: 'auto' }}>
                        <Typography variant="body2" color="text.secondary">
                          {review.testimonial}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
            </Box>
            <MobileStepper
              variant="progress"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1, margin: 'auto' }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        </Grid>
        <Awards />
        <StarScore />
      </Container>
    </Box>
  );
}
