import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  CssBaseline,
  Container,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavbarV2 from './NavbarV2';
import Footerv2 from "./FooterV2"
import BASE_URL from './ipconfig'; // Ensure this path is correct

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Gallery() {
  const [pictures, setPictures] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchPictures();

    // Set up interval for rotating images
    const pictureInterval = setInterval(() => {
      setCurrentPictureIndex((prevIndex) =>
        (prevIndex + 1) % pictures.length
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(pictureInterval);
  }, [pictures.length]);

  const fetchPictures = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/pictures`);
      setPictures(response.data);
    } catch (error) {
      console.error('Error fetching pictures:', error);
    }
  };

  const handleClickOpen = (picture) => {
    setCurrentImage(picture);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', margin: '0 auto', overflow: 'hidden' }}>
    <CssBaseline />

    <NavbarV2 />
    
    <Box sx={{  backgroundColor: '#002855',color: '#fff', minHeight: '90vh', flexGrow: 3, display: 'flex', flexDirection: 'column', mt: theme.spacing(8) }}>
      
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
            <NavbarV2 />
            </Grid>
            {/* Left text content */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                OUR PREVIOUS WORK
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: '#FFCC00', fontWeight: 'bold', mb: 2 }}
              >
                See Our Quality Craftsmanship
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                “We take pride in delivering the best plumbing services. Our
                gallery showcases the dedication and skill of our team.”
                <br />
                <strong>– Tornado Plumbing & Drains Team</strong>
              </Typography>
            </Grid>

            {/* Right rotating image content */}
            <Grid item xs={12} sm={6}>
              {pictures.length > 0 && (
                <Box sx={{ textAlign: 'center', my: 2 }}>
                  <Box
                    component="img"
                    src={`${BASE_URL}/${pictures[currentPictureIndex].path}`}
                    alt={pictures[currentPictureIndex].title}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '400px',
                      borderRadius: '8px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            {pictures.map((picture, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: '1px solid #ddd',
                    textAlign: 'center',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden',
                    transition: 'transform 0.2s',
                    backgroundColor: '#fff',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => handleClickOpen(picture)}
                >
                  {/* Image */}
                  <Box sx={{ width: '100%', height: '200px' }}>
                    <img
                      src={`${BASE_URL}/${picture.path}`}
                      alt={picture.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                  {/* Title */}
                  <Box
                    sx={{
                      width: '100%',
                      padding: 1,
                      backgroundColor: '#FFCC00',
                      color: '#002855',
                      textAlign: 'left',
                    }}
                  >
                    <Typography variant="subtitle1">
                      {picture.title}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
 
        </Container>
    
      </Box>



      {/* Dialog for Image Preview */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            maxWidth: '90vw',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {currentImage.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            component="img"
            src={`${BASE_URL}/${currentImage.path}`}
            alt={currentImage.title}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Footerv2/>
    </Box>
  );
}
